<template>
  <layout :activeIndex="activeIndex" :activeModel.sync="activeModel" :topBarItems="topBarItems" @setIndex="setIndex">
    <template #left-0>
      <left-menu :floors="floors" :system="system" @select="leftMenuSelect"></left-menu>
    </template>
    <template #left-1>
      <b-card style="height: calc(100vh - 20rem); overflow-y: auto">
        <h4>筛选</h4>
        <div class="pt-1">
          <label>设备名称:</label>
          <b-form-input id="name" placeholder="输入设备名称查询" trim></b-form-input>
        </div>
        <div class="pt-1">
          <label>区域:</label>
          <b-form-select id="area"></b-form-select>
        </div>
        <div class="pt-1">
          <label>楼层:</label>
          <b-form-select id="floor"></b-form-select>
        </div>
      </b-card>
    </template>
    <template #left-2>
      <left-menu :list="floors" @select="selectVideoFloor"></left-menu>
    </template>
    <template #right-0>
      <!--      <component :is="key"></component>-->
    </template>
    <template #right-1>
      <b-card no-body>
        <b-table :current-page="currentPage" :fields="fields" :items="items" :per-page="perPage" hover responsive
                 striped>
          <template v-slot:cell(params)="data">
            <template v-for="(param,param_index) in data.item.params">
                <span v-if="param.param_name" :style="param.cur_value===null?'opacity:0.3':''">
                  <span class="mx-50" v-if="param_index!==0">,</span>
                  <span>{{ param.param_name.replace(data.item.equip_name + '-', '') }}</span>:
                  <span v-if="param.cur_value===null">N/A</span>
                  <span v-else>{{
                      param.unit && (param.unit.indexOf('||') > -1) ? param.unit.split('||')[param.cur_value > 0 ? 1 : 0] : (param.cur_value + ' ' + (param.unit || ''))
                    }}</span>
                </span>
            </template>
          </template>
          <template #cell(action)="data">
            <div class="text-center">
              <b-button v-if="data.item.ip_address" variant="warning" size="sm">查看</b-button>
              <b-button variant="success" size="sm" @click="equip_to_detail_model(data.item)"
                        :disabled="!data.item.detail_model_key">详情
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>

      <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="items.length" align="right" first-number
                    hide-goto-end-buttons last-number next-class="next-item" prev-class="prev-item"></b-pagination>

    </template>
    <template #right-2>
      <div class="px-1">
        <h2 class="text-center">{{ videoFloor }}</h2>
        <div class="mt-2">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                :aria-describedby="ariaDescribedby"
                name="flavour-2"
                v-model="videoShowList"
            >
              <b-row>
                <b-col md="6" lg="3" v-for="(video,videoIndex) in videoList" :key="'video_checkbox_'+videoIndex">
                  <b-form-checkbox :value="video.equip_id">
                    <span>{{ video.equip_name }}<small class="ml-50">({{ video.location }})</small></span>
                  </b-form-checkbox>
                </b-col>
              </b-row>

            </b-form-checkbox-group>
          </b-form-group>

        </div>
        <b-row class="mt-2">
          <b-col cols="3" v-for="(video,videoIndex) in videoList" v-if="videoShowList.includes(video.equip_id)"
                 :key="videoIndex">
            <b-card>
              <h5>{{ video.equip_name }}<small class="ml-1">{{ video.location }}</small></h5>
              <div>
                <b-button
                    v-b-tooltip.hover
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="btn-icon position-absolute"
                    size="sm"
                    style="right: 1rem; top: 1rem; z-index: 3; padding: 0.2rem"
                    variant="primary"
                >
                  <feather-icon icon="MaximizeIcon"/>
                </b-button>
              </div>
              <div class="position-relative w-100" style="height: 16rem">
                <video v-if="video.ip_address" :id="'intelligent-security_video_'+video.equip_id"
                       class="h-100 w-100"></video>
                <div v-else class="h-100 w-100 text-center pt-4 position-absolute"
                     style="top: 0; background-color: rgba(0, 0, 0, 0.5)">
                  <h6>播放异常</h6>
                  <small>此点位未配置摄像头推流地址</small>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </template>
    <template #right-3>
      <b-card no-body>
        <b-table :fields="alarm_fields" :items="alarm_items" hover responsive
                 striped>
          <template #cell(action)="data">
            <b-button
                variant="outline-success"
                class="px-50"
                size="sm"
            >
              解除报警
            </b-button>
            <b-button
                variant="outline-warning"
                class="px-50 ml-50"
                size="sm"
            >
              生成工单
            </b-button>
          </template>
        </b-table>
      </b-card>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import {provide, reactive, ref, toRefs, watch} from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flvjs from "flv.js";
import {showToast} from "@/libs/utils/showToast";

export default {
  name: "intelligent-security",
  components: {
    LeftMenu,
    Layout,
    panelGroup: () => import("@/views/panel-group"),
    deviceList: () => import("./components/deviceList"),
    videoSurveillance: () => import("./components/videoSurveillance"),
    accessControl: () => import("./components/accessControl"),
    intrusionAlerts: () => import("./components/intrusionAlerts"),
    staffing: () => import("./components/staffing"),
  },
  directives: {
    Ripple,
  },
  setup() {
    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: {
            path: "surveillance_system/surveillance/surveillance",
            isDetail: false,
            dbIds: ["equipment_"],
            rotate: "48466,-110529,41064",
          },
        },
        {title: "设备列表"},
        {title: "监控轮巡"},
        {title: "报警记录"},
      ],
      key: "",

    });
    //左侧菜单相关参数
    const leftMenuParams = reactive({
      floors: [
        {
          title: "ROOF",
          value: 12,
          model: {path: "surveillance_system/surveillance_ROOF/surveillance_ROOF", dbIds: ["equipment_MJ"]},
        },
        {
          title: "F12",
          value: 12,
          model: {
            path: "surveillance_system/surveillance_F12/surveillance_F12",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F11",
          value: 11,
          model: {
            path: "surveillance_system/surveillance_F11/surveillance_F11",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F10",
          value: 10,
          model: {
            path: "surveillance_system/surveillance_F10/surveillance_F10",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F9",
          value: 9,
          model: {
            path: "surveillance_system/surveillance_F9/surveillance_F9",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F8",
          value: 8,
          model: {
            path: "surveillance_system/surveillance_F8/surveillance_F8",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F7",
          value: 7,
          model: {
            path: "surveillance_system/surveillance_F7/surveillance_F7",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F6",
          value: 6,
          model: {
            path: "surveillance_system/surveillance_F6/surveillance_F6",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F5",
          value: 5,
          model: {
            path: "surveillance_system/surveillance_F5/surveillance_F5",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F3",
          value: 4,
          model: {
            path: "surveillance_system/surveillance_F3/surveillance_F3",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F2",
          value: 3,
          model: {
            path: "surveillance_system/surveillance_F2/surveillance_F2",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "F1",
          value: 2,
          model: {
            path: "surveillance_system/surveillance_F1/surveillance_F1",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "B1",
          value: 1,
          model: {
            path: "surveillance_system/surveillance_B1/surveillance_B1",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "B2",
          value: -2,
          model: {
            path: "surveillance_system/surveillance_B2/surveillance_B2",
            dbIds: ["equipment_SXT", "equipment_MJ"],
          },
        },
        {
          title: "B3",
          value: -3,
          model: {path: "surveillance_system/surveillance_B3/surveillance_B3", dbIds: ["equipment_SXT"]},
        },
        {
          title: "B5",
          value: -5,
          model: {path: "surveillance_system/surveillance_B5/surveillance_B5", dbIds: ["equipment_SXT"]},
        },
      ],
      system: true,
    });
    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      console.log("leftMenuSelectEvent", event);
      if (event?.equip_name) {
        event.model.isDetail = true;
        layoutParams.activeModel = event.model;
      } else {
        event.model.isDetail = false;
        layoutParams.activeModel = event.model;
      }
    };

    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };

    const key = ref("staffingIndex");
    provide("key", key);
    const changeSystem = (v) => {
      layoutParams.key = v.key;
      if (v.key === "staffing") {
        key.value = "staffingIndex";
      }
    };

    const data = reactive({
      fields: [
        {label: "ID", key: "equip_id"},
        {label: "设备名称", key: "equip_name"},
        {label: "设备编号", key: "equip_meter_code"},
        {label: "楼层", key: "floor"},
        {label: "参数状态", key: "params"},
        {label: "操作", key: "action"},
      ],
      items: [],
      perPage: 20,
      currentPage: 1,
      rows: 1,
      select: 0,
      videoFloor: '',
      videoList: [],
      videoShowList: [],
      videoPlayerList: {},
      alarm_fields: [
        {label: "#", key: "index"},
        {label: "报警名称", key: "alarm_name"},
        {label: "报警设备", key: "alarm_equipment"},
        {label: "报警位置", key: "alarm_location"},
        {label: "报警信息", key: "alarm_text"},
        {label: "报警时间", key: "alarm_time"},
        {label: "操作", key: "action"},
      ],
      alarm_items: [
        {
          index: 1,
          alarm_name: "demo1",
          alarm_equipment: "demo1",
          alarm_location: "demo1",
          alarm_text: "demo1",
          alarm_time: "2022-01-01 00:00:00"
        }
      ]
    })
    watch(() => store.state.jsf35.equip_list, (list) => {
      data.items = list
      data.rows = list.length
    })
    //设备列表跳转到模型大样图
    const equip_to_detail_model = (item) => {
      setIndex(0)
      setTimeout(() => {
        store.commit("jsf35/setEquipSelected", item);
        leftMenuSelect(item)
      }, 1000)
    }

    //选中需要查看摄像头画面的楼层
    const selectVideoFloor = ($event) => {
      const floor = $event.title
      data.videoFloor = floor
      data.videoList = store.state.jsf35.equip_list.filter(item => item.group_lvl_2 === floor)
    }

    watch(() => data.videoShowList, (list, old_list) => {
      console.log('old_list', old_list)
      console.log('list', list)
      let new_video_list = []
      let destroy_video_list = []
      list.map(item => {
        if (!old_list.includes(item)) {
          //new_video_list.push(item)
          videoPlay(item)
        }
      })
      old_list.map(item => {
        if (!list.includes(item)) {
          //destroy_video_list.push(item)
          videoPlayDestroy(item)
        }
      })
    })

    //销毁视频播放器
    const videoPlayDestroy = (videoId)=>{
      if (data.videoPlayerList[videoId]) {
        data.videoPlayerList[videoId].detachMediaElement();
        data.videoPlayerList[videoId].destroy();
        data.videoPlayerList[videoId] = null;
      }
    }

    //初始化视频播放器，并开始播放
    const videoPlay = (videoId) => {
      if (flvjs.isSupported()) {
        let equip_item = data.videoList.find((item) => item.equip_id === videoId)
        let url = equip_item.ip_address
        console.log(videoId + ':' + url)
        videoPlayDestroy(videoId)
        const flvVideo = flvjs.createPlayer({
          type: "flv",
          url: url,
        });
        let videoDom;
        videoDom = document.getElementById('intelligent-security_video_' + videoId);
        flvVideo.attachMediaElement(videoDom);
        flvVideo.load();
        let error_msg = "";
        flvVideo.on("error", function (error) {
          error_msg = error;
          showToast(null, "播放错误", "安防设备视频画面仅供内网访问");
        });
        //没有异常信息时播放
        if (!error_msg) {
          data.videoPlayerList[videoId] = flvVideo;
          flvVideo.play();
        }
      }
    };

    watch(() => layoutParams.activeIndex, (activeIndex) => {
      if (activeIndex === 2) {
        data.videoList = []
        data.videoFloor = ''
        data.videoShowList = []
        data.videoPlayerList = {}
      }
    })


    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      ...toRefs(data),
      leftMenuSelect,
      setIndex,
      changeSystem,
      equip_to_detail_model,
      selectVideoFloor
    };
  },
};
</script>

<style lang="scss"></style>
